.dm-clients {
  padding-top: 50px;
  text-align: center;
  background: $seashell;
}

.dm-clients__title {
  font-size: 24px;
  font-weight: bolder;
  display: inline-block;
  margin: 0 auto 30px;
  color: $dove-gray;
}

.dm-clients__logos {
  position: relative;
}

.dm-clients__picture {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  height: 200px;
  padding: 30px;
  vertical-align: top;
  border-bottom: 1px solid $iron;

  @media (min-width: $screen-sm-min) {
    width: 50%;
    border-right: 1px solid $iron;

    &:nth-child(even) {
      border-right: none;
    }
  }

  @media (min-width: $screen-md-min) {
    width: 25%;

    &:nth-child(even) {
      border-right: 1px solid $iron;
    }
  }
}

.dm-clients__picture:nth-child(4n) {
  border-right: none;
}

.dm-clients__image {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  transform: translateY(-50%);
}

.dm-clients__cta {
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  transform: translateY(-50%);
  padding: 10px;
}
