.dm-checks {
  position: relative;
  z-index: 10;
  max-width: 800px;
  margin: 50px auto;
  padding: 0;
  list-style: none;
  columns: 1;

  @media (min-width: $screen-md-min) {
    columns: 2;
  }
}

.dm-checks__item {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 10px;
  padding-left: 25px;
  color: $dove-gray;
  break-inside: avoid-column;

  &::before {
    font-family: 'Glyphicons Halflings';
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    content: '\e084';
    color: $cerulean;
  }
}
