.nav-icon {
  position: relative;
  z-index: 20;
  width: 45px;
  height: 35px;
  margin: 10px auto;
  cursor: pointer;
  transition: .5s ease-in-out;
  transform: rotate(0deg);
}

.nav-icon.open {
  margin-top: 20px;
}

.nav-icon span {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 7px;
  transition: .25s ease-in-out;
  transform: rotate(0deg);
  opacity: 1;
  border-radius: 9px;
  background: $cerulean;
}

.nav-icon span:nth-child(1) {
  top: 0;
}

.nav-icon span:nth-child(2) {
  top: 14px;
}

.nav-icon span:nth-child(3) {
  top: 29px;
}

.nav-icon.open span:nth-child(1) {
  top: 14px;
  transform: rotate(135deg);
}

.nav-icon.open span:nth-child(2) {
  left: -60px;
  opacity: 0;
}

.nav-icon.open span:nth-child(3) {
  top: 14px;
  transform: rotate(-135deg);
}
