.dm-features {
  border-bottom: 5px solid $cerulean;
  background: $bondi-blue url('/images/shattered.png') repeat;
}

.dm-features__announcement {
  padding: 20px 10px;
  text-align: center;
  background: rgba(white, .1);
}

.dm-features__title {
  font-size: 22px;
  max-width: 600px;
  margin: 0 auto;
  color: white;
}

.dm-features__features-list {
  border: 1px solid mix(white, $shakespeare, 20%);
  display: inline-block;
  width: 100%;
  margin: 0 0 -5px;
  padding: 0;
  list-style: none;
}

.dm-features__item {
  font-size: 16px;
  position: relative;
  float: left;
  width: 100%;
  height: 260px;
  text-align: center;
  color: white;

  @media (min-width: $screen-sm-min) {
    width: 50%;
  }

  @media (min-width: $screen-md-min) {
    width: 33.33%;
  }

  @media (min-width: $scree-extra-lg-min) {
    width: 16.66%;
  }
}

.dm-features__item .dm-features__item-front {
  background: rgba(white, .2);
}

.dm-features__item:nth-child(odd) .dm-features__item-front {
  background: rgba(white, .3);
}

.dm-features__item .dm-features__item-back {
  background: rgba(black, .2);

  @media (min-width: $screen-lg-min) {
    padding: 20px;
  }
}
