.dm-showcase {
  overflow: hidden;
  padding: 50px 10px 0;
  text-align: center;
  border-bottom: 5px solid $cerulean;
}

.dm-showcase .container {
  margin-bottom: -15px;
}

.dm-showcase__title {
  font-size: 28px;
  font-weight: bolder;
  display: inline-block;
  color: $dove-gray;
}
