.btn {
  font-size: 12px;
  font-weight: bold;
  z-index: 10;
  padding: 10px 40px;
  text-transform: uppercase;
  color: white;

  @media (min-width: $screen-md-min) {
    font-size: 18px;
  }
}

.btn--block {
  margin: 20px auto;
}

.btn--primary {
  @include pseudo-color(white, $cerulean);
}

.btn--secondary {
  @include pseudo-color(white, $safety-orange);
}
