html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

body.open {
  overflow: hidden;
}

.help-block.with-errors {
  text-align: left;
}

input.form-control {
  margin-right: 10px;
}
