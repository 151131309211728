.dm-menu {
  display: none;
  margin: 0 10px;
  padding: 0;
  list-style: none;

  @media (min-width: $screen-md-min) {
    display: inline-block;
  }

  @media (min-width: $screen-lg-min) {
    margin: 0 20px;
  }
}

.nav-icon.open + .dm-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  padding: 90px 20px 10px;
  text-align: center;
  overflow-y: scroll;
  background: rgba(black, .9);
}

.dm-menu__item {
  display: inline-block;

  .nav-icon.open + .dm-menu & {
    display: block;
  }
}

.dm-menu__link {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin: 0 10px;
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  text-shadow: 1px 1px 0 rgba(black, .2);
  @include pseudo-link($cerulean, $cerulean, 2px);

  @media (min-width: $screen-lg-min) {
    margin: 0 15px;
  }

  .nav-icon.open + .dm-menu & {
    font-size: 24px;
  }
}
