.dm-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  padding: 0 20px 0;
  text-align: center;
  color: white;
  background: url('/images/back-header.jpg') no-repeat center center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  @include shadow-overlay;

  @media (min-width: $screen-lg-min) {
    padding-top: 100px;
  }
}

.dm-hero__logo {
  position: relative;
  z-index: 10;
  max-width: 300px;
  margin-bottom: 10px;

  @media (min-width: $screen-lg-min) {
    max-width: 100%;
    margin-bottom: 50px;
  }
}

.dm-hero__image {
  width: 100%;
}

.dm-hero__title {
  font-size: 28px;
  font-weight: bold;
  position: relative;
  z-index: 10;
  margin: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  border-bottom: 5px solid $cerulean;

  @media (min-width: $screen-md-min) {
    font-size: 52px;
    line-height: 1.5;
  }
}

.dm-hero__subtitle {
  font-size: 14px;
  font-weight: lighter;
  position: relative;
  z-index: 10;
  margin: 5px auto 20px;
  text-transform: lowercase;

  @media (min-width: $screen-md-min) {
    font-size: 26px;
    margin-bottom: 50px;
  }
}

.dm-hero__login {
  position: absolute;
  z-index: 10;
  top: 25px;
  right: 20px;

  @media (min-width: $screen-md-min) {
    right: 50px;
  }
}
