.dm-profiles {
  position: relative;
  border-top: 5px solid $cerulean;
  border-bottom: 5px solid $cerulean;
  background: url('/images/back-about-us.jpg') no-repeat center center;
  background-size: cover;
  @include shadow-overlay(white, .8);
}

.dm-profiles__title {
  font-size: 32px;
  position: relative;
  z-index: 10;
  margin: 40px auto 30px;
  text-align: center;
  color: $cerulean;
}

.dm-profiles__description {
  font-size: 16px;
  position: relative;
  z-index: 10;
  text-align: justify;
  color: $dove-gray;
}
