.dm-showcase-slider {
  display: none;
  padding: 20px 10px 0;
  text-align: center;
}

.dm-showcase-slider.is-active {
  position: relative;
  display: block;
}

.dm-showcase-slider__title {
  font-size: 16px;
  line-height: 1.1;
  display: inline-block;
  margin: 10px auto 50px;
  text-align: justify;
  color: $dove-gray;
}

.dm-showcase-slider__counter {
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);

  @media (min-width: $screen-md-min) {
    right: -50px;
  }
}

ol.dm-showcase-slider__counter-list {
  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

li.dm-showcase-slider__counter-item {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 5px;
  border: none;
  border-radius: 50%;
  background: $iron;
}

li.dm-showcase-slider__counter-item.active {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 5px;
  border: none;
  border-radius: 50%;
  background: $cerulean;
}

.dm-showcase-slider__slide-container {
  position: relative;
  display: inline-block;
  width: 100%;

  @media (min-width: $screen-md-min) {
    width: 65%;
  }
}

.dm-showcase-slider__slide {
  position: relative;
  display: inline-block;
  padding: 12% 1% 1%;
  vertical-align: top;
  border-radius: 10px 10px 0 0;
  background: url('/images/browser-mockup.png') no-repeat;
  background-size: contain;
}

.dm-showcase-slider__slide .carousel {
  width: 98%;
  margin: 0 auto;
  top: -10px;

  @media (min-width: $screen-lg-min) {
    top: -15px;
  }
}

.dm-showcase-slider__shadow {
  width: 100%;
  padding-bottom: 10px;
}

.dm-showcase-slider__image {
  display: inline-block;
  width: 100%;
}

.dm-showcase-slider__checks-container {
  display: inline-block;
  float: left;
  width: 100%;
  margin-right: 20px;
  margin-left: -20px;
  vertical-align: top;

  @media (min-width: $screen-md-min) {
    max-width: 30%;
    margin-left: 0;
  }
}

.dm-showcase-slider__checks {
  margin-top: 20px;
  columns: 1;
}

.dm-showcase-slider__checks .dm-checks__item {
  font-size: 12px;
  text-align: justify;
}
