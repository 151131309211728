.dm-showcase-tabs {
  margin: 0;
  padding: 0 0 20px;
  list-style: none;
  border-bottom: 2px solid $cerulean;
}

.dm-showcase-tabs__item {
  display: inline-block;

  &:first-child .dm-showcase-tabs__link {
    border-radius: 6px 0 0 6px;
  }

  &:last-child .dm-showcase-tabs__link {
    border-radius: 0 6px 6px 0;
  }
}

.dm-showcase-tabs__link {
  font-size: 16px;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid $cerulean;
  @include pseudo-color($cerulean, transparent);

  &::before,
  &::after {
    position: absolute;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    top: 45px;
    content: '';
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity .3s ease-out;
  }
}

.dm-showcase-tabs__link.is-active {
  position: relative;
  color: white;
  background: $cerulean;
  transition: all .8s;

  &::before,
  &::after {
    opacity: 1;
  }

  &::before {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $cerulean;
  }

  &::after {
    top: 49px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid white;
  }
}
