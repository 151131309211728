.flip-container {
  perspective: 1000;
}

.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 100%;
  height: 100%;
}

.flipper {
  border: 1px solid mix(white, $shakespeare, 20%);
  position: relative;
  width: 100%;
  height: 100%;
  transition: .6s;
  transform-style: preserve-3d;
}

.front, .back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}
