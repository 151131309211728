@mixin shadow-overlay($color: black, $shadow: .65) {
  &::after {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: rgba($color, $shadow);
  }
}

@mixin pseudo-color($color, $background) {
  color: $color;
  background: $background;

  &:visited {
    text-decoration: none;
    color: $color;
    background: mix(black, $background, 20%);
  }

  &:focus, &:hover {
    text-decoration: none;
    color: $color;
    background: mix(white, $background, 20%);
  }

  &:active {
    text-decoration: none;
    color: $color;
    background: mix(white, $background, 50%);
  }
}

@mixin pseudo-link($color, $border: transparent, $width: 1px) {
  color: $color;

  &:visited {
    text-decoration: none;
    color: $color;
  }

  &:focus, &:hover {
    text-decoration: none;
    color: $color;
    border-bottom: $width solid $border;
  }

  &:active {
    text-decoration: none;
    color: $color;
  }
}
