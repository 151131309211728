header {
  position: fixed;
  z-index: 50;
  top: 0;
  display: block;
  width: 100%;
  height: 60px;
  transition: all .8s;

  @media (min-width: $screen-lg-min) {
    height: 80px;
  }
}

header.is-visible {
  transition: all .5s;
  background: rgba($zircon, .95);
  box-shadow: 2px 2px 3px rgba(black, .2);
}

header.is-visible .dm-menu__link {
  text-shadow: none;
}

.header__nav-icon {
  display: inline-block;

  @media (min-width: $screen-md-min) {
    display: none;
  }
}

.header__logo {
  display: none;

  @media (min-width: $screen-md-min) {
    display: inline-block;
    margin: 5px 10px;
  }

  @media (min-width: $screen-lg-min) {
    margin: 6px 50px 5px;
  }
}

.header__image {
  width: 100%;
  max-width: 90px;

  @media (min-width: $screen-lg-min) {
    max-width: 130px;
  }
}

.header__image--blue {
  display: none;
}

.header__login {
  float: right;
  margin-top: 10px;

  @media (min-width: $screen-md-min) {
    margin: 5px;
  }

  @media (min-width: $screen-lg-min) {
    margin: 15px 10px 5px;
  }
}

header.is-visible .header__login {
  @include pseudo-color(white, $cerulean);
}

header.is-visible .header__image--white {
  display: none;
}

header.is-visible .header__image--blue {
  display: inline-block;
}
