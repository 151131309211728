.dm-contact {
  padding: 50px 0 20px;
  background: url('/images/back-mapa.jpg') no-repeat;
  background-size: cover;
}

.dm-contact__info {
  padding: 0 10px;
}

.dm-contact__title {
  font-size: 38px;
  font-weight: bolder;
  margin: 40px 0 20px;
  color: $dove-gray;
}

.dm-contact__text {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  color: $dove-gray;
  margin-right: 10px;
  white-space: nowrap;
}

.dm-contact__text a {
  @include pseudo-link($dove-gray);
}

.dm-contact__icon {
  display: inline-block;
  width: 39px;
  height: 36px;
  margin: 2px 10px 2px 0;
  vertical-align: middle;
}

.dm-contact__icon.icon-phone {
  background: url('/images/icon-tel.png') center center no-repeat;
}

.dm-contact__icon.icon-email {
  background: url('/images/icon-contact.png') center center no-repeat;
}

.dm-contact__form textarea.form-control, .dm-contact__form input.form-control {
  border-radius: 2px;
}

.dm-contact__form .row .col-md-6:first-child input.form-control {
  width: 95%;
}

.dm-contact__form textarea.form-control {
  min-width: 100%;
  max-width: 100%;
}
