.dm-demo-call {
  padding: 50px 20px;
  text-align: center;
  background: $zircon;
}

.dm-demo-call__title {
  font-size: 22px;
  font-weight: normal;
  display: inline-block;
  margin: 10px 20px 20px;
  color: $dove-gray;
}
