$cerulean: #11a9da;
$shakespeare: #51accc;
$pelorous: #389cc3;
$bondi-blue: #0087b7;
$lochmara: #0081c0;
$bahama-blue: #006492;
$dove-gray: #686868;
$iron: #ccc;
$zircon: #e1e1e1;
$seashell: #f1f1f1;
$safety-orange: #f90;

$scree-extra-lg-min: 1600px;
