.dm-feature {
  display: inline-block;
}

.dm-feature__image {
  width: 100%;
  max-width: 110px;
  border-radius: 40% 40% 5px 40%;
  box-shadow: 0px 0px 0 5px white inset;
}

.dm-feature__title {
  font-size: 16px;
  padding-top: 5px;
  border-top: 1px solid white;
}

.dm-feature--back {
  position: relative;
  width: 100%;
  height: 100%;
}

.dm-feature__description {
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;

  @media (min-width: $screen-md-min) {
    font-size: 14px;
  }
}
