.dm-demo-subscription {
  position: relative;
  padding: 100px 20px;
  text-align: center;
  border-bottom: 5px solid $cerulean;
  background: url('/images/back-demo.jpg') no-repeat center center;
  background-size: cover;
  @include shadow-overlay;
}

.dm-demo-subscription__title {
  font-size: 34px;
  line-height: 1.2;
  position: relative;
  max-width: 700px;
  margin: 0 auto 30px;
  color: white;
  z-index: 10;
}

.dm-demo-subscription__title strong {
  color: $cerulean;
}

.dm-demo-subscription__form {
  position: relative;
  z-index: 10;
}

.dm-demo-subscription__form input.form-control {
  min-width: 300px;
  text-align: center;
  border-radius: 2px;
}
