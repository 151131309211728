footer {
  padding: 40px 0 0;
  background: $lochmara;
}

.footer__title {
  font-size: 18px;
  font-weight: bolder;
  display: block;
  margin: 20px 0 5px;
  text-transform: uppercase;
  color: white;
}

.footer__subtitle {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  color: white;
}

.footer__copyright {
  font-size: 13px;
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px 15px;
  text-align: center;
  color: white;
  background: $bahama-blue;
}

.footer__form {
  display: block;
  margin: 0 20px;
  text-align: center;
  vertical-align: middle;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    text-align: left;
  }
}

.footer__form input.form-control {
  width: 100%;
  margin-top: 10px;
  padding: 23px 10px;
  color: $cerulean;
  border: none;
  border-radius: 3px;
  background: $bahama-blue;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    width: 300px;
  }
}

.footer__link {
  display: block;
  margin: 5px 0;
  @include pseudo-link(white);
}

.footer__form button {
  display: block;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    width: auto;
  }
}
